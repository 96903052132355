import Uppy from '@uppy/core'
import React, {useState, useEffect, useCallback} from 'react'
import {useGetUploaderDataQuery, useUpdateFolderUploadMutation, useInsertFolderUploadMutation} from '../../generated/graphql';
import {Dashboard} from '@uppy/react'
import {
    Flex,
    Grid,
    Heading,
    Select,
    useColorMode,
    VStack,
    Text,
    Button,
    useColorModeValue,
    Box
} from "@chakra-ui/react";
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Compressor from '@uppy/compressor'
import { Image } from '@chakra-ui/react'
import {useParams} from "react-router-dom";
import {ColorModeSwitcher} from "../../ColorModeSwitcher";
import {useNavigate} from "react-router-dom";
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
const uppy = new Uppy({
    debug: true,
    autoProceed: true,
    restrictions: {
        allowedFileTypes: ['image/*']
    },
})

uppy.use(Compressor, {
    quality: 0.6,
    limit: 10,
})

uppy.use(AwsS3Multipart, {
    limit: 4,
    companionUrl: 'https://companion.ascendile.com',
})

export function UploaderMainPage() {
    const navigate = useNavigate();
    const {colorMode, toggleColorMode} = useColorMode()
    const [currentLabelName, setCurrentLabelName] = useState('');
    const [currentLabelId, setCurrentLabelId] = useState(0);
    const [uploadDone, setUploadDone] = useState(false);
    let {folderId} = useParams();
    const [updateUploads] = useUpdateFolderUploadMutation();
    const [insertUploads] = useInsertFolderUploadMutation();
    const {loading, error, data, refetch} = useGetUploaderDataQuery({
        variables: {
            'folderId': folderId!
        },
        pollInterval: 1000,
    });

    let currentLabelExists = false;

    const handleUploadComplete = useCallback(
        (result: { successful: string | any[]; failed: any; }) => {
            console.log(`Inside handleUploadComplete value is ${currentLabelExists}`)
            if (currentLabelExists) {
                updateUploads({
                    variables: {
                        uploadedPhotos: result.successful.length,
                        folderId: folderId!,
                        labelId: currentLabelId
                    }
                }).then(() => {
                    console.log("Files successfully uploaded.")
                    refetch({folderId: folderId})
                })
            } else {
                insertUploads({
                    variables: {
                        uploadedPhotos: result.successful.length,
                        folderId: folderId!,
                        labelId: currentLabelId
                    }
                }).then(() => {
                    console.log("Files successfully uploaded.")
                    refetch({folderId: folderId})
                })
            }

            console.log('failed files:', result.failed)
        },
        [updateUploads, refetch, currentLabelId, folderId]
    )

    useEffect(() => {
        const handler = (result: any) => {
            handleUploadComplete(result);
        };
        uppy.on('complete', handler);
        // Tell React to remove the old listener if a different function is passed to the `handleUploadComplete` prop:
        return () => {
            uppy.off('complete', handler);
        };
    }, [handleUploadComplete]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        console.log(JSON.stringify(error));
        return <p>Error...</p>;
    }
    if (data?.gdrive_folder?.length! <= 0) {
        return <p>We don't have a record of this folder...</p>;
    }
    let gdrive_uploads: { __typename?: "gdrive_folder_upload" | undefined; created_at: any; n_uploaded_photos: number; label_id: number; updated_at: any; }[] = []

    data?.gdrive_folder_upload.forEach( folderUpload => {
        gdrive_uploads.push(folderUpload)
        if (folderUpload.label_id === currentLabelId) {
            currentLabelExists = true
        }
    })
    const imageUrl = data?.gdrive_folder[0]!.imageUrls![0]
    console.log(imageUrl)
    const currentLabelUpload = gdrive_uploads.find(element => element.label_id === currentLabelId)

    uppy.setOptions({
        onBeforeFileAdded: currentFile => {
            let name = currentFile.name;
            return {
                ...currentFile,
                name: `${folderId}/${currentLabelName}-${name}`,
            };
        },
    });

    return (
        <Grid minH="100vh" p={2}>
            <Flex direction={"row"} justifyContent={"center"}>
                <Flex justify={"flex-start"} align={"center"} direction={"column"} justifySelf={"center"}>
                    <Flex justify={"center"}>
                        <Heading>Ascent Uploader</Heading>
                    </Flex>
                </Flex>
                {/*<Flex justifySelf={"end"}>*/}
                {/*    <ColorModeSwitcher/>*/}
                {/*</Flex>*/}
            </Flex>
            <VStack spacing={12}>
                <Flex justify={"center"} alignContent={"center"} textAlign={"center"} flexDirection={"column"}>
                    <Select placeholder='Select option'
                            onChange={(e) => {
                                setCurrentLabelName(e.target[e.target.selectedIndex].getAttribute('data-value')!)
                                console.log(`Our value is ${e.target[e.target.selectedIndex].getAttribute('data-value')!}`)
                                setCurrentLabelId(parseInt(e.target.value))
                            }}
                    >
                        {
                            data?.photo_label.map((label, index) => {
                                return (
                                    <option key={index}
                                            data-value={data?.photo_label[index].name}
                                            value={data?.photo_label[index].id}>{data?.photo_label[index].name}</option>
                                )
                            })
                        }
                    </Select>
                </Flex>
                <Dashboard
                    width={"90vw"}
                    theme={colorMode}
                    locale={{
                        strings: {
                            dropPasteFiles: (currentLabelName === null || currentLabelName?.length <= 0) ? 'Please select a label to start uploading' : "Drop files here or %{browseFiles}",
                        }
                    }}
                    disabled={currentLabelName === null || currentLabelName?.length <= 0}
                    proudlyDisplayPoweredByUppy={false}
                    uppy={uppy}>
                    onBeforeFileAdded
                </Dashboard>
                {
                    currentLabelUpload
                        ?
                        (
                            <Text>Number of uploaded pictures to <b>{currentLabelName}</b> category is <b>{currentLabelUpload.n_uploaded_photos}</b></Text>
                        ) :
                        <Text>No photos have been uploaded to this folder yet</Text>
                }
                <Text>Total number of photos uploaded - {data?.gdrive_folder_upload_aggregate.aggregate?.sum?.n_uploaded_photos}</Text>
                {
                    uploadDone
                        ?
                        (
                            <Button colorScheme='red' onClick={() => navigate('/farewell')}>Close</Button>
                        ) :
                        null
                }
                <Button colorScheme='blue' onClick={() => navigate(`/upload/${folderId}/images`)}>Check folder images</Button>
            </VStack>
        </Grid>

    )
}
