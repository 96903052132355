import * as React from "react"
import {ChakraProvider} from "@chakra-ui/react"
import {UploaderMainPage} from "./pages/uploader";
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {FarewellPage} from "./pages/farewell";
import theme from "./theme";
import {FolderImages} from "./pages/uploader/images";

const client = new ApolloClient({
    uri: 'https://api.ascendile.com/v1/graphql',
    cache: new InMemoryCache()
});


export const App = () => (
    <ChakraProvider theme={theme} >
        {/*<Box textAlign="center" fontSize="xl">*/}
        {/*  /!*<UploaderMainPage></UploaderMainPage>*!/*/}
        {/*  /!*<Grid minH="100vh" p={3}>*!/*/}
        {/*  /!*  <ColorModeSwitcher justifySelf="flex-end" />*!/*/}
        {/*  /!*  <VStack spacing={8}>*!/*/}
        {/*  /!*    <Logo h="40vmin" pointerEvents="none" />*!/*/}
        {/*  /!*    <Text>*!/*/}
        {/*  /!*      Edit <Code fontSize="xl">src/App.tsx</Code> and save to reload.*!/*/}
        {/*  /!*    </Text>*!/*/}
        {/*  /!*    <Link*!/*/}
        {/*  /!*      color="teal.500"*!/*/}
        {/*  /!*      href="https://chakra-ui.com"*!/*/}
        {/*  /!*      fontSize="2xl"*!/*/}
        {/*  /!*      target="_blank"*!/*/}
        {/*  /!*      rel="noopener noreferrer"*!/*/}
        {/*  /!*    >*!/*/}
        {/*  /!*      Learn Chakra*!/*/}
        {/*  /!*    </Link>*!/*/}
        {/*  /!*  </VStack>*!/*/}
        {/*  /!*</Grid>*!/*/}
        {/*</Box>*/}
        <ApolloProvider client={client}>
            <BrowserRouter>
                <Routes>
                    <Route path={"/upload/:folderId"} element={<UploaderMainPage/>}/>
                    <Route path={"/upload/:folderId/images"} element={<FolderImages/>}/>
                    <Route path={"/farewell"} element={<FarewellPage/>}/>
                </Routes>
            </BrowserRouter>
        </ApolloProvider>
    </ChakraProvider>
)
