import React from 'react'
import {Box, Container, Flex, Heading, Stack, Text, useBreakpointValue, useColorModeValue} from "@chakra-ui/react";
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'


export function FarewellPage() {
    return (
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
                <Flex direction={"row"} justifyContent={"center"}>
                    <Flex justify={"flex-start"} align={"center"} direction={"column"} justifySelf={"center"}>
                        <Flex justify={"center"}>
                            <Heading>Ascent Uploader</Heading>
                        </Flex>
                    </Flex>
                </Flex>
                <Box
                    display='flex'
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '6', sm: '10' }}
                    bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
                    boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                    textAlign={"center"}
                >
                    <Text fontSize='3xl' color={"grey"} >
                        Thank you for using Ascent photo uploader
                    </Text>
                </Box>
            </Stack>
        </Container>
    )
}
