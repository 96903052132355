import Uppy from '@uppy/core'
import React, {useState, useEffect, useCallback} from 'react'
import {useGetUploaderDataQuery, useUpdateFolderUploadMutation, useInsertFolderUploadMutation} from '../../generated/graphql';
import {Dashboard} from '@uppy/react'
import {
    Flex,
    Grid,
    Heading,
    Select,
    useColorMode,
    VStack,
    Text,
    Button,
    useColorModeValue,
    Box, GridItem
} from "@chakra-ui/react";
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Compressor from '@uppy/compressor'
import { Image } from '@chakra-ui/react'
import {useParams} from "react-router-dom";
import {ColorModeSwitcher} from "../../ColorModeSwitcher";
import {useNavigate} from "react-router-dom";
import '@uppy/core/dist/style.css'

import '@uppy/dashboard/dist/style.css'
const uppy = new Uppy({
    debug: true,
    autoProceed: true,
    restrictions: {
        allowedFileTypes: ['image/*']
    },
})

uppy.use(Compressor, {
    quality: 0.6,
    limit: 10,
})

uppy.use(AwsS3Multipart, {
    limit: 4,
    companionUrl: 'https://companion.ascendile.com',
})

export function FolderImages() {
    const navigate = useNavigate();
    let {folderId} = useParams();
    const {loading, error, data, refetch} = useGetUploaderDataQuery({
        variables: {
            'folderId': folderId!
        },
        pollInterval: 1000,
    });
    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        console.log(JSON.stringify(error));
        return <p>Error...</p>;
    }
    if (data?.gdrive_folder?.length! <= 0) {
        return <p>We don't have a record of this folder...</p>;
    }
    let gdrive_uploads: { __typename?: "gdrive_folder_upload" | undefined; created_at: any; n_uploaded_photos: number; label_id: number; updated_at: any; }[] = []

    const imageUrls = data?.gdrive_folder[0]!.imageUrls!

    return (
        <Grid minH="100vh" p={2}>
            <Flex direction={"row"} justifyContent={"center"} style={{padding:'50px'}}>
                <Flex justify={"flex-start"} align={"center"} direction={"column"} justifySelf={"center"}>
                    <Flex justify={"center"}>
                        <Heading>Ascent Uploader</Heading>
                    </Flex>
                    <Flex>
                        <Text>{data?.gdrive_folder[0]!.name}</Text>
                    </Flex>
                    <Flex style={{padding:'20px'}}>
                        <Button colorScheme='blue' onClick={() => navigate(`/upload/${folderId}`)}>Return to uploading images</Button>
                    </Flex>
                </Flex>
            </Flex>
            <Grid templateColumns='repeat(5, 1fr)' gap={6} style={{padding:'25px', border:'1px solid #bfbfbf', background:'#fafafa', borderRadius:'10px'}}>
                {imageUrls.map(imageUrl => {
                    return (
                        <div style={{display:"flex", flexDirection:"column", justifyContent:'center'}}>
                            <Image src={imageUrl!}/>
                        </div>
                    )
                })}
            </Grid>
        </Grid>

    )

}
