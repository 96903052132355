import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
export type GetUploaderDataQueryVariables = Exact<{
  folderId: Scalars['String'];
}>;


export type GetUploaderDataQuery = { __typename?: 'query_root', gdrive_folder: Array<{ __typename?: 'gdrive_folder', name: string, imageUrls?: Array<string | null> | null }>, photo_label: Array<{ __typename?: 'photo_label', id: number, name: string, created_at: any, updated_at: any, order: any }>, gdrive_folder_upload: Array<{ __typename?: 'gdrive_folder_upload', created_at: any, n_uploaded_photos: number, label_id: number, updated_at: any }>, gdrive_folder_upload_aggregate: { __typename?: 'gdrive_folder_upload_aggregate', aggregate?: { __typename?: 'gdrive_folder_upload_aggregate_fields', sum?: { __typename?: 'gdrive_folder_upload_sum_fields', n_uploaded_photos?: number | null } | null } | null } };

export type InsertFolderUploadMutationVariables = Exact<{
  uploadedPhotos: Scalars['Int'];
  folderId: Scalars['String'];
  labelId: Scalars['Int'];
}>;


export type InsertFolderUploadMutation = { __typename?: 'mutation_root', insert_gdrive_folder_upload?: { __typename?: 'gdrive_folder_upload_mutation_response', returning: Array<{ __typename?: 'gdrive_folder_upload', n_uploaded_photos: number }> } | null };

export type UpdateFolderUploadMutationVariables = Exact<{
  uploadedPhotos: Scalars['Int'];
  folderId: Scalars['String'];
  labelId: Scalars['Int'];
}>;


export type UpdateFolderUploadMutation = { __typename?: 'mutation_root', update_gdrive_folder_upload?: { __typename?: 'gdrive_folder_upload_mutation_response', returning: Array<{ __typename?: 'gdrive_folder_upload', folder_id: string, label_id: number, n_uploaded_photos: number }> } | null };


export const GetUploaderDataDocument = gql`
    query getUploaderData($folderId: String!) {
  gdrive_folder(where: {id: {_eq: $folderId}}) {
    name
    imageUrls
  }
  photo_label(order_by: {order: asc}) {
    id
    name
    created_at
    updated_at
    order
  }
  gdrive_folder_upload(where: {folder_id: {_eq: $folderId}}) {
    created_at
    n_uploaded_photos
    label_id
    updated_at
  }
  gdrive_folder_upload_aggregate(where: {folder_id: {_eq: $folderId}}) {
    aggregate {
      sum {
        n_uploaded_photos
      }
    }
  }
}
    `;

/**
 * __useGetUploaderDataQuery__
 *
 * To run a query within a React component, call `useGetUploaderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploaderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploaderDataQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useGetUploaderDataQuery(baseOptions: Apollo.QueryHookOptions<GetUploaderDataQuery, GetUploaderDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploaderDataQuery, GetUploaderDataQueryVariables>(GetUploaderDataDocument, options);
      }
export function useGetUploaderDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploaderDataQuery, GetUploaderDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploaderDataQuery, GetUploaderDataQueryVariables>(GetUploaderDataDocument, options);
        }
export type GetUploaderDataQueryHookResult = ReturnType<typeof useGetUploaderDataQuery>;
export type GetUploaderDataLazyQueryHookResult = ReturnType<typeof useGetUploaderDataLazyQuery>;
export type GetUploaderDataQueryResult = Apollo.QueryResult<GetUploaderDataQuery, GetUploaderDataQueryVariables>;
export const InsertFolderUploadDocument = gql`
    mutation InsertFolderUpload($uploadedPhotos: Int!, $folderId: String!, $labelId: Int!) {
  insert_gdrive_folder_upload(
    objects: {folder_id: $folderId, label_id: $labelId, n_uploaded_photos: $uploadedPhotos}
    on_conflict: {constraint: gdrive_folder_upload_pkey, update_columns: []}
  ) {
    returning {
      n_uploaded_photos
    }
  }
}
    `;
export type InsertFolderUploadMutationFn = Apollo.MutationFunction<InsertFolderUploadMutation, InsertFolderUploadMutationVariables>;

/**
 * __useInsertFolderUploadMutation__
 *
 * To run a mutation, you first call `useInsertFolderUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFolderUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFolderUploadMutation, { data, loading, error }] = useInsertFolderUploadMutation({
 *   variables: {
 *      uploadedPhotos: // value for 'uploadedPhotos'
 *      folderId: // value for 'folderId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useInsertFolderUploadMutation(baseOptions?: Apollo.MutationHookOptions<InsertFolderUploadMutation, InsertFolderUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFolderUploadMutation, InsertFolderUploadMutationVariables>(InsertFolderUploadDocument, options);
      }
export type InsertFolderUploadMutationHookResult = ReturnType<typeof useInsertFolderUploadMutation>;
export type InsertFolderUploadMutationResult = Apollo.MutationResult<InsertFolderUploadMutation>;
export type InsertFolderUploadMutationOptions = Apollo.BaseMutationOptions<InsertFolderUploadMutation, InsertFolderUploadMutationVariables>;
export const UpdateFolderUploadDocument = gql`
    mutation UpdateFolderUpload($uploadedPhotos: Int!, $folderId: String!, $labelId: Int!) {
  update_gdrive_folder_upload(
    where: {folder_id: {_eq: $folderId}, label_id: {_eq: $labelId}}
    _inc: {n_uploaded_photos: $uploadedPhotos}
  ) {
    returning {
      folder_id
      label_id
      n_uploaded_photos
    }
  }
}
    `;
export type UpdateFolderUploadMutationFn = Apollo.MutationFunction<UpdateFolderUploadMutation, UpdateFolderUploadMutationVariables>;

/**
 * __useUpdateFolderUploadMutation__
 *
 * To run a mutation, you first call `useUpdateFolderUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderUploadMutation, { data, loading, error }] = useUpdateFolderUploadMutation({
 *   variables: {
 *      uploadedPhotos: // value for 'uploadedPhotos'
 *      folderId: // value for 'folderId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useUpdateFolderUploadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFolderUploadMutation, UpdateFolderUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFolderUploadMutation, UpdateFolderUploadMutationVariables>(UpdateFolderUploadDocument, options);
      }
export type UpdateFolderUploadMutationHookResult = ReturnType<typeof useUpdateFolderUploadMutation>;
export type UpdateFolderUploadMutationResult = Apollo.MutationResult<UpdateFolderUploadMutation>;
export type UpdateFolderUploadMutationOptions = Apollo.BaseMutationOptions<UpdateFolderUploadMutation, UpdateFolderUploadMutationVariables>;

export const GetUploaderData = gql`
    query getUploaderData($folderId: String!) {
  gdrive_folder(where: {id: {_eq: $folderId}}) {
    name
    imageUrls
  }
  photo_label(order_by: {order: asc}) {
    id
    name
    created_at
    updated_at
    order
  }
  gdrive_folder_upload(where: {folder_id: {_eq: $folderId}}) {
    created_at
    n_uploaded_photos
    label_id
    updated_at
  }
  gdrive_folder_upload_aggregate(where: {folder_id: {_eq: $folderId}}) {
    aggregate {
      sum {
        n_uploaded_photos
      }
    }
  }
}
    `;
export const InsertFolderUpload = gql`
    mutation InsertFolderUpload($uploadedPhotos: Int!, $folderId: String!, $labelId: Int!) {
  insert_gdrive_folder_upload(
    objects: {folder_id: $folderId, label_id: $labelId, n_uploaded_photos: $uploadedPhotos}
    on_conflict: {constraint: gdrive_folder_upload_pkey, update_columns: []}
  ) {
    returning {
      n_uploaded_photos
    }
  }
}
    `;
export const UpdateFolderUpload = gql`
    mutation UpdateFolderUpload($uploadedPhotos: Int!, $folderId: String!, $labelId: Int!) {
  update_gdrive_folder_upload(
    where: {folder_id: {_eq: $folderId}, label_id: {_eq: $labelId}}
    _inc: {n_uploaded_photos: $uploadedPhotos}
  ) {
    returning {
      folder_id
      label_id
      n_uploaded_photos
    }
  }
}
    `;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  float8: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "gdrive_folder" */
export type Gdrive_Folder = {
  __typename?: 'gdrive_folder';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  gdrive_folder_uploads: Array<Gdrive_Folder_Upload>;
  /** An aggregate relationship */
  gdrive_folder_uploads_aggregate: Gdrive_Folder_Upload_Aggregate;
  id: Scalars['String'];
  imageUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "gdrive_folder" */
export type Gdrive_FolderGdrive_Folder_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Upload_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Upload_Bool_Exp>;
};


/** columns and relationships of "gdrive_folder" */
export type Gdrive_FolderGdrive_Folder_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Upload_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Upload_Bool_Exp>;
};

/** aggregated selection of "gdrive_folder" */
export type Gdrive_Folder_Aggregate = {
  __typename?: 'gdrive_folder_aggregate';
  aggregate?: Maybe<Gdrive_Folder_Aggregate_Fields>;
  nodes: Array<Gdrive_Folder>;
};

/** aggregate fields of "gdrive_folder" */
export type Gdrive_Folder_Aggregate_Fields = {
  __typename?: 'gdrive_folder_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdrive_Folder_Max_Fields>;
  min?: Maybe<Gdrive_Folder_Min_Fields>;
};


/** aggregate fields of "gdrive_folder" */
export type Gdrive_Folder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gdrive_Folder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gdrive_folder". All fields are combined with a logical 'AND'. */
export type Gdrive_Folder_Bool_Exp = {
  _and?: InputMaybe<Array<Gdrive_Folder_Bool_Exp>>;
  _not?: InputMaybe<Gdrive_Folder_Bool_Exp>;
  _or?: InputMaybe<Array<Gdrive_Folder_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gdrive_folder_uploads?: InputMaybe<Gdrive_Folder_Upload_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdrive_folder" */
export enum Gdrive_Folder_Constraint {
  /** unique or primary key constraint on columns "id" */
  GdriveFolderPkey = 'gdrive_folder_pkey'
}

/** input type for inserting data into table "gdrive_folder" */
export type Gdrive_Folder_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gdrive_folder_uploads?: InputMaybe<Gdrive_Folder_Upload_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gdrive_Folder_Max_Fields = {
  __typename?: 'gdrive_folder_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Gdrive_Folder_Min_Fields = {
  __typename?: 'gdrive_folder_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "gdrive_folder" */
export type Gdrive_Folder_Mutation_Response = {
  __typename?: 'gdrive_folder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdrive_Folder>;
};

/** input type for inserting object relation for remote table "gdrive_folder" */
export type Gdrive_Folder_Obj_Rel_Insert_Input = {
  data: Gdrive_Folder_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Gdrive_Folder_On_Conflict>;
};

/** on_conflict condition type for table "gdrive_folder" */
export type Gdrive_Folder_On_Conflict = {
  constraint: Gdrive_Folder_Constraint;
  update_columns?: Array<Gdrive_Folder_Update_Column>;
  where?: InputMaybe<Gdrive_Folder_Bool_Exp>;
};

/** Ordering options when selecting data from "gdrive_folder". */
export type Gdrive_Folder_Order_By = {
  created_at?: InputMaybe<Order_By>;
  gdrive_folder_uploads_aggregate?: InputMaybe<Gdrive_Folder_Upload_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "gdrive_folder_parent" */
export type Gdrive_Folder_Parent = {
  __typename?: 'gdrive_folder_parent';
  created_at: Scalars['timestamptz'];
  folder_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "gdrive_folder_parent" */
export type Gdrive_Folder_Parent_Aggregate = {
  __typename?: 'gdrive_folder_parent_aggregate';
  aggregate?: Maybe<Gdrive_Folder_Parent_Aggregate_Fields>;
  nodes: Array<Gdrive_Folder_Parent>;
};

/** aggregate fields of "gdrive_folder_parent" */
export type Gdrive_Folder_Parent_Aggregate_Fields = {
  __typename?: 'gdrive_folder_parent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdrive_Folder_Parent_Max_Fields>;
  min?: Maybe<Gdrive_Folder_Parent_Min_Fields>;
};


/** aggregate fields of "gdrive_folder_parent" */
export type Gdrive_Folder_Parent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gdrive_Folder_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gdrive_folder_parent". All fields are combined with a logical 'AND'. */
export type Gdrive_Folder_Parent_Bool_Exp = {
  _and?: InputMaybe<Array<Gdrive_Folder_Parent_Bool_Exp>>;
  _not?: InputMaybe<Gdrive_Folder_Parent_Bool_Exp>;
  _or?: InputMaybe<Array<Gdrive_Folder_Parent_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  folder_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdrive_folder_parent" */
export enum Gdrive_Folder_Parent_Constraint {
  /** unique or primary key constraint on columns "folder_id" */
  GdriveFolderParentPkey = 'gdrive_folder_parent_pkey'
}

/** input type for inserting data into table "gdrive_folder_parent" */
export type Gdrive_Folder_Parent_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  folder_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gdrive_Folder_Parent_Max_Fields = {
  __typename?: 'gdrive_folder_parent_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  folder_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Gdrive_Folder_Parent_Min_Fields = {
  __typename?: 'gdrive_folder_parent_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  folder_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "gdrive_folder_parent" */
export type Gdrive_Folder_Parent_Mutation_Response = {
  __typename?: 'gdrive_folder_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdrive_Folder_Parent>;
};

/** on_conflict condition type for table "gdrive_folder_parent" */
export type Gdrive_Folder_Parent_On_Conflict = {
  constraint: Gdrive_Folder_Parent_Constraint;
  update_columns?: Array<Gdrive_Folder_Parent_Update_Column>;
  where?: InputMaybe<Gdrive_Folder_Parent_Bool_Exp>;
};

/** Ordering options when selecting data from "gdrive_folder_parent". */
export type Gdrive_Folder_Parent_Order_By = {
  created_at?: InputMaybe<Order_By>;
  folder_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gdrive_folder_parent */
export type Gdrive_Folder_Parent_Pk_Columns_Input = {
  folder_id: Scalars['String'];
};

/** select columns of table "gdrive_folder_parent" */
export enum Gdrive_Folder_Parent_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FolderId = 'folder_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gdrive_folder_parent" */
export type Gdrive_Folder_Parent_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  folder_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "gdrive_folder_parent" */
export enum Gdrive_Folder_Parent_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FolderId = 'folder_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdrive_Folder_Parent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gdrive_Folder_Parent_Set_Input>;
  where: Gdrive_Folder_Parent_Bool_Exp;
};

/** primary key columns input for table: gdrive_folder */
export type Gdrive_Folder_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "gdrive_folder" */
export enum Gdrive_Folder_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gdrive_folder" */
export type Gdrive_Folder_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "gdrive_folder" */
export enum Gdrive_Folder_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdrive_Folder_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gdrive_Folder_Set_Input>;
  where: Gdrive_Folder_Bool_Exp;
};

/** columns and relationships of "gdrive_folder_upload" */
export type Gdrive_Folder_Upload = {
  __typename?: 'gdrive_folder_upload';
  created_at: Scalars['timestamptz'];
  folder_id: Scalars['String'];
  /** An object relationship */
  gdrive_folder: Gdrive_Folder;
  label_id: Scalars['Int'];
  n_uploaded_photos: Scalars['Int'];
  /** An object relationship */
  photo_label: Photo_Label;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Aggregate = {
  __typename?: 'gdrive_folder_upload_aggregate';
  aggregate?: Maybe<Gdrive_Folder_Upload_Aggregate_Fields>;
  nodes: Array<Gdrive_Folder_Upload>;
};

/** aggregate fields of "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Aggregate_Fields = {
  __typename?: 'gdrive_folder_upload_aggregate_fields';
  avg?: Maybe<Gdrive_Folder_Upload_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gdrive_Folder_Upload_Max_Fields>;
  min?: Maybe<Gdrive_Folder_Upload_Min_Fields>;
  stddev?: Maybe<Gdrive_Folder_Upload_Stddev_Fields>;
  stddev_pop?: Maybe<Gdrive_Folder_Upload_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gdrive_Folder_Upload_Stddev_Samp_Fields>;
  sum?: Maybe<Gdrive_Folder_Upload_Sum_Fields>;
  var_pop?: Maybe<Gdrive_Folder_Upload_Var_Pop_Fields>;
  var_samp?: Maybe<Gdrive_Folder_Upload_Var_Samp_Fields>;
  variance?: Maybe<Gdrive_Folder_Upload_Variance_Fields>;
};


/** aggregate fields of "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gdrive_Folder_Upload_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Aggregate_Order_By = {
  avg?: InputMaybe<Gdrive_Folder_Upload_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Gdrive_Folder_Upload_Max_Order_By>;
  min?: InputMaybe<Gdrive_Folder_Upload_Min_Order_By>;
  stddev?: InputMaybe<Gdrive_Folder_Upload_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Gdrive_Folder_Upload_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Gdrive_Folder_Upload_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Gdrive_Folder_Upload_Sum_Order_By>;
  var_pop?: InputMaybe<Gdrive_Folder_Upload_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Gdrive_Folder_Upload_Var_Samp_Order_By>;
  variance?: InputMaybe<Gdrive_Folder_Upload_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Arr_Rel_Insert_Input = {
  data: Array<Gdrive_Folder_Upload_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Gdrive_Folder_Upload_On_Conflict>;
};

/** aggregate avg on columns */
export type Gdrive_Folder_Upload_Avg_Fields = {
  __typename?: 'gdrive_folder_upload_avg_fields';
  label_id?: Maybe<Scalars['Float']>;
  n_uploaded_photos?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Avg_Order_By = {
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "gdrive_folder_upload". All fields are combined with a logical 'AND'. */
export type Gdrive_Folder_Upload_Bool_Exp = {
  _and?: InputMaybe<Array<Gdrive_Folder_Upload_Bool_Exp>>;
  _not?: InputMaybe<Gdrive_Folder_Upload_Bool_Exp>;
  _or?: InputMaybe<Array<Gdrive_Folder_Upload_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  folder_id?: InputMaybe<String_Comparison_Exp>;
  gdrive_folder?: InputMaybe<Gdrive_Folder_Bool_Exp>;
  label_id?: InputMaybe<Int_Comparison_Exp>;
  n_uploaded_photos?: InputMaybe<Int_Comparison_Exp>;
  photo_label?: InputMaybe<Photo_Label_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdrive_folder_upload" */
export enum Gdrive_Folder_Upload_Constraint {
  /** unique or primary key constraint on columns "label_id", "folder_id" */
  GdriveFolderUploadPkey = 'gdrive_folder_upload_pkey'
}

/** input type for incrementing numeric columns in table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Inc_Input = {
  label_id?: InputMaybe<Scalars['Int']>;
  n_uploaded_photos?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  folder_id?: InputMaybe<Scalars['String']>;
  gdrive_folder?: InputMaybe<Gdrive_Folder_Obj_Rel_Insert_Input>;
  label_id?: InputMaybe<Scalars['Int']>;
  n_uploaded_photos?: InputMaybe<Scalars['Int']>;
  photo_label?: InputMaybe<Photo_Label_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gdrive_Folder_Upload_Max_Fields = {
  __typename?: 'gdrive_folder_upload_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  folder_id?: Maybe<Scalars['String']>;
  label_id?: Maybe<Scalars['Int']>;
  n_uploaded_photos?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  folder_id?: InputMaybe<Order_By>;
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Gdrive_Folder_Upload_Min_Fields = {
  __typename?: 'gdrive_folder_upload_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  folder_id?: Maybe<Scalars['String']>;
  label_id?: Maybe<Scalars['Int']>;
  n_uploaded_photos?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  folder_id?: InputMaybe<Order_By>;
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Mutation_Response = {
  __typename?: 'gdrive_folder_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdrive_Folder_Upload>;
};

/** on_conflict condition type for table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_On_Conflict = {
  constraint: Gdrive_Folder_Upload_Constraint;
  update_columns?: Array<Gdrive_Folder_Upload_Update_Column>;
  where?: InputMaybe<Gdrive_Folder_Upload_Bool_Exp>;
};

/** Ordering options when selecting data from "gdrive_folder_upload". */
export type Gdrive_Folder_Upload_Order_By = {
  created_at?: InputMaybe<Order_By>;
  folder_id?: InputMaybe<Order_By>;
  gdrive_folder?: InputMaybe<Gdrive_Folder_Order_By>;
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
  photo_label?: InputMaybe<Photo_Label_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gdrive_folder_upload */
export type Gdrive_Folder_Upload_Pk_Columns_Input = {
  folder_id: Scalars['String'];
  label_id: Scalars['Int'];
};

/** select columns of table "gdrive_folder_upload" */
export enum Gdrive_Folder_Upload_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FolderId = 'folder_id',
  /** column name */
  LabelId = 'label_id',
  /** column name */
  NUploadedPhotos = 'n_uploaded_photos',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  folder_id?: InputMaybe<Scalars['String']>;
  label_id?: InputMaybe<Scalars['Int']>;
  n_uploaded_photos?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Gdrive_Folder_Upload_Stddev_Fields = {
  __typename?: 'gdrive_folder_upload_stddev_fields';
  label_id?: Maybe<Scalars['Float']>;
  n_uploaded_photos?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Stddev_Order_By = {
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Gdrive_Folder_Upload_Stddev_Pop_Fields = {
  __typename?: 'gdrive_folder_upload_stddev_pop_fields';
  label_id?: Maybe<Scalars['Float']>;
  n_uploaded_photos?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Stddev_Pop_Order_By = {
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Gdrive_Folder_Upload_Stddev_Samp_Fields = {
  __typename?: 'gdrive_folder_upload_stddev_samp_fields';
  label_id?: Maybe<Scalars['Float']>;
  n_uploaded_photos?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Stddev_Samp_Order_By = {
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Gdrive_Folder_Upload_Sum_Fields = {
  __typename?: 'gdrive_folder_upload_sum_fields';
  label_id?: Maybe<Scalars['Int']>;
  n_uploaded_photos?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Sum_Order_By = {
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
};

/** update columns of table "gdrive_folder_upload" */
export enum Gdrive_Folder_Upload_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FolderId = 'folder_id',
  /** column name */
  LabelId = 'label_id',
  /** column name */
  NUploadedPhotos = 'n_uploaded_photos',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdrive_Folder_Upload_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Gdrive_Folder_Upload_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gdrive_Folder_Upload_Set_Input>;
  where: Gdrive_Folder_Upload_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gdrive_Folder_Upload_Var_Pop_Fields = {
  __typename?: 'gdrive_folder_upload_var_pop_fields';
  label_id?: Maybe<Scalars['Float']>;
  n_uploaded_photos?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Var_Pop_Order_By = {
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Gdrive_Folder_Upload_Var_Samp_Fields = {
  __typename?: 'gdrive_folder_upload_var_samp_fields';
  label_id?: Maybe<Scalars['Float']>;
  n_uploaded_photos?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Var_Samp_Order_By = {
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Gdrive_Folder_Upload_Variance_Fields = {
  __typename?: 'gdrive_folder_upload_variance_fields';
  label_id?: Maybe<Scalars['Float']>;
  n_uploaded_photos?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gdrive_folder_upload" */
export type Gdrive_Folder_Upload_Variance_Order_By = {
  label_id?: InputMaybe<Order_By>;
  n_uploaded_photos?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "gdrive_folder" */
  delete_gdrive_folder?: Maybe<Gdrive_Folder_Mutation_Response>;
  /** delete single row from the table: "gdrive_folder" */
  delete_gdrive_folder_by_pk?: Maybe<Gdrive_Folder>;
  /** delete data from the table: "gdrive_folder_parent" */
  delete_gdrive_folder_parent?: Maybe<Gdrive_Folder_Parent_Mutation_Response>;
  /** delete single row from the table: "gdrive_folder_parent" */
  delete_gdrive_folder_parent_by_pk?: Maybe<Gdrive_Folder_Parent>;
  /** delete data from the table: "gdrive_folder_upload" */
  delete_gdrive_folder_upload?: Maybe<Gdrive_Folder_Upload_Mutation_Response>;
  /** delete single row from the table: "gdrive_folder_upload" */
  delete_gdrive_folder_upload_by_pk?: Maybe<Gdrive_Folder_Upload>;
  /** delete data from the table: "photo_label" */
  delete_photo_label?: Maybe<Photo_Label_Mutation_Response>;
  /** delete single row from the table: "photo_label" */
  delete_photo_label_by_pk?: Maybe<Photo_Label>;
  /** insert data into the table: "gdrive_folder" */
  insert_gdrive_folder?: Maybe<Gdrive_Folder_Mutation_Response>;
  /** insert a single row into the table: "gdrive_folder" */
  insert_gdrive_folder_one?: Maybe<Gdrive_Folder>;
  /** insert data into the table: "gdrive_folder_parent" */
  insert_gdrive_folder_parent?: Maybe<Gdrive_Folder_Parent_Mutation_Response>;
  /** insert a single row into the table: "gdrive_folder_parent" */
  insert_gdrive_folder_parent_one?: Maybe<Gdrive_Folder_Parent>;
  /** insert data into the table: "gdrive_folder_upload" */
  insert_gdrive_folder_upload?: Maybe<Gdrive_Folder_Upload_Mutation_Response>;
  /** insert a single row into the table: "gdrive_folder_upload" */
  insert_gdrive_folder_upload_one?: Maybe<Gdrive_Folder_Upload>;
  /** insert data into the table: "photo_label" */
  insert_photo_label?: Maybe<Photo_Label_Mutation_Response>;
  /** insert a single row into the table: "photo_label" */
  insert_photo_label_one?: Maybe<Photo_Label>;
  /** update data of the table: "gdrive_folder" */
  update_gdrive_folder?: Maybe<Gdrive_Folder_Mutation_Response>;
  /** update single row of the table: "gdrive_folder" */
  update_gdrive_folder_by_pk?: Maybe<Gdrive_Folder>;
  /** update multiples rows of table: "gdrive_folder" */
  update_gdrive_folder_many?: Maybe<Array<Maybe<Gdrive_Folder_Mutation_Response>>>;
  /** update data of the table: "gdrive_folder_parent" */
  update_gdrive_folder_parent?: Maybe<Gdrive_Folder_Parent_Mutation_Response>;
  /** update single row of the table: "gdrive_folder_parent" */
  update_gdrive_folder_parent_by_pk?: Maybe<Gdrive_Folder_Parent>;
  /** update multiples rows of table: "gdrive_folder_parent" */
  update_gdrive_folder_parent_many?: Maybe<Array<Maybe<Gdrive_Folder_Parent_Mutation_Response>>>;
  /** update data of the table: "gdrive_folder_upload" */
  update_gdrive_folder_upload?: Maybe<Gdrive_Folder_Upload_Mutation_Response>;
  /** update single row of the table: "gdrive_folder_upload" */
  update_gdrive_folder_upload_by_pk?: Maybe<Gdrive_Folder_Upload>;
  /** update multiples rows of table: "gdrive_folder_upload" */
  update_gdrive_folder_upload_many?: Maybe<Array<Maybe<Gdrive_Folder_Upload_Mutation_Response>>>;
  /** update data of the table: "photo_label" */
  update_photo_label?: Maybe<Photo_Label_Mutation_Response>;
  /** update single row of the table: "photo_label" */
  update_photo_label_by_pk?: Maybe<Photo_Label>;
  /** update multiples rows of table: "photo_label" */
  update_photo_label_many?: Maybe<Array<Maybe<Photo_Label_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Gdrive_FolderArgs = {
  where: Gdrive_Folder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdrive_Folder_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Gdrive_Folder_ParentArgs = {
  where: Gdrive_Folder_Parent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdrive_Folder_Parent_By_PkArgs = {
  folder_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Gdrive_Folder_UploadArgs = {
  where: Gdrive_Folder_Upload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdrive_Folder_Upload_By_PkArgs = {
  folder_id: Scalars['String'];
  label_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Photo_LabelArgs = {
  where: Photo_Label_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Photo_Label_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_Gdrive_FolderArgs = {
  objects: Array<Gdrive_Folder_Insert_Input>;
  on_conflict?: InputMaybe<Gdrive_Folder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdrive_Folder_OneArgs = {
  object: Gdrive_Folder_Insert_Input;
  on_conflict?: InputMaybe<Gdrive_Folder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdrive_Folder_ParentArgs = {
  objects: Array<Gdrive_Folder_Parent_Insert_Input>;
  on_conflict?: InputMaybe<Gdrive_Folder_Parent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdrive_Folder_Parent_OneArgs = {
  object: Gdrive_Folder_Parent_Insert_Input;
  on_conflict?: InputMaybe<Gdrive_Folder_Parent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdrive_Folder_UploadArgs = {
  objects: Array<Gdrive_Folder_Upload_Insert_Input>;
  on_conflict?: InputMaybe<Gdrive_Folder_Upload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdrive_Folder_Upload_OneArgs = {
  object: Gdrive_Folder_Upload_Insert_Input;
  on_conflict?: InputMaybe<Gdrive_Folder_Upload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Photo_LabelArgs = {
  objects: Array<Photo_Label_Insert_Input>;
  on_conflict?: InputMaybe<Photo_Label_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Photo_Label_OneArgs = {
  object: Photo_Label_Insert_Input;
  on_conflict?: InputMaybe<Photo_Label_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdrive_FolderArgs = {
  _set?: InputMaybe<Gdrive_Folder_Set_Input>;
  where: Gdrive_Folder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdrive_Folder_By_PkArgs = {
  _set?: InputMaybe<Gdrive_Folder_Set_Input>;
  pk_columns: Gdrive_Folder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdrive_Folder_ManyArgs = {
  updates: Array<Gdrive_Folder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdrive_Folder_ParentArgs = {
  _set?: InputMaybe<Gdrive_Folder_Parent_Set_Input>;
  where: Gdrive_Folder_Parent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdrive_Folder_Parent_By_PkArgs = {
  _set?: InputMaybe<Gdrive_Folder_Parent_Set_Input>;
  pk_columns: Gdrive_Folder_Parent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdrive_Folder_Parent_ManyArgs = {
  updates: Array<Gdrive_Folder_Parent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdrive_Folder_UploadArgs = {
  _inc?: InputMaybe<Gdrive_Folder_Upload_Inc_Input>;
  _set?: InputMaybe<Gdrive_Folder_Upload_Set_Input>;
  where: Gdrive_Folder_Upload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdrive_Folder_Upload_By_PkArgs = {
  _inc?: InputMaybe<Gdrive_Folder_Upload_Inc_Input>;
  _set?: InputMaybe<Gdrive_Folder_Upload_Set_Input>;
  pk_columns: Gdrive_Folder_Upload_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdrive_Folder_Upload_ManyArgs = {
  updates: Array<Gdrive_Folder_Upload_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Photo_LabelArgs = {
  _inc?: InputMaybe<Photo_Label_Inc_Input>;
  _set?: InputMaybe<Photo_Label_Set_Input>;
  where: Photo_Label_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Photo_Label_By_PkArgs = {
  _inc?: InputMaybe<Photo_Label_Inc_Input>;
  _set?: InputMaybe<Photo_Label_Set_Input>;
  pk_columns: Photo_Label_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Photo_Label_ManyArgs = {
  updates: Array<Photo_Label_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "photo_label" */
export type Photo_Label = {
  __typename?: 'photo_label';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "photo_label" */
export type Photo_Label_Aggregate = {
  __typename?: 'photo_label_aggregate';
  aggregate?: Maybe<Photo_Label_Aggregate_Fields>;
  nodes: Array<Photo_Label>;
};

/** aggregate fields of "photo_label" */
export type Photo_Label_Aggregate_Fields = {
  __typename?: 'photo_label_aggregate_fields';
  avg?: Maybe<Photo_Label_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Photo_Label_Max_Fields>;
  min?: Maybe<Photo_Label_Min_Fields>;
  stddev?: Maybe<Photo_Label_Stddev_Fields>;
  stddev_pop?: Maybe<Photo_Label_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Photo_Label_Stddev_Samp_Fields>;
  sum?: Maybe<Photo_Label_Sum_Fields>;
  var_pop?: Maybe<Photo_Label_Var_Pop_Fields>;
  var_samp?: Maybe<Photo_Label_Var_Samp_Fields>;
  variance?: Maybe<Photo_Label_Variance_Fields>;
};


/** aggregate fields of "photo_label" */
export type Photo_Label_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Photo_Label_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Photo_Label_Avg_Fields = {
  __typename?: 'photo_label_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "photo_label". All fields are combined with a logical 'AND'. */
export type Photo_Label_Bool_Exp = {
  _and?: InputMaybe<Array<Photo_Label_Bool_Exp>>;
  _not?: InputMaybe<Photo_Label_Bool_Exp>;
  _or?: InputMaybe<Array<Photo_Label_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "photo_label" */
export enum Photo_Label_Constraint {
  /** unique or primary key constraint on columns "id" */
  PhotoLabelIdKey = 'photo_label_id_key',
  /** unique or primary key constraint on columns "id" */
  PhotoLabelPkey = 'photo_label_pkey'
}

/** input type for incrementing numeric columns in table "photo_label" */
export type Photo_Label_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "photo_label" */
export type Photo_Label_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Photo_Label_Max_Fields = {
  __typename?: 'photo_label_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Photo_Label_Min_Fields = {
  __typename?: 'photo_label_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "photo_label" */
export type Photo_Label_Mutation_Response = {
  __typename?: 'photo_label_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Photo_Label>;
};

/** input type for inserting object relation for remote table "photo_label" */
export type Photo_Label_Obj_Rel_Insert_Input = {
  data: Photo_Label_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Photo_Label_On_Conflict>;
};

/** on_conflict condition type for table "photo_label" */
export type Photo_Label_On_Conflict = {
  constraint: Photo_Label_Constraint;
  update_columns?: Array<Photo_Label_Update_Column>;
  where?: InputMaybe<Photo_Label_Bool_Exp>;
};

/** Ordering options when selecting data from "photo_label". */
export type Photo_Label_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: photo_label */
export type Photo_Label_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "photo_label" */
export enum Photo_Label_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "photo_label" */
export type Photo_Label_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Photo_Label_Stddev_Fields = {
  __typename?: 'photo_label_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Photo_Label_Stddev_Pop_Fields = {
  __typename?: 'photo_label_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Photo_Label_Stddev_Samp_Fields = {
  __typename?: 'photo_label_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Photo_Label_Sum_Fields = {
  __typename?: 'photo_label_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['float8']>;
};

/** update columns of table "photo_label" */
export enum Photo_Label_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Photo_Label_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Photo_Label_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Photo_Label_Set_Input>;
  where: Photo_Label_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Photo_Label_Var_Pop_Fields = {
  __typename?: 'photo_label_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Photo_Label_Var_Samp_Fields = {
  __typename?: 'photo_label_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Photo_Label_Variance_Fields = {
  __typename?: 'photo_label_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "gdrive_folder" */
  gdrive_folder: Array<Gdrive_Folder>;
  /** fetch aggregated fields from the table: "gdrive_folder" */
  gdrive_folder_aggregate: Gdrive_Folder_Aggregate;
  /** fetch data from the table: "gdrive_folder" using primary key columns */
  gdrive_folder_by_pk?: Maybe<Gdrive_Folder>;
  /** fetch data from the table: "gdrive_folder_parent" */
  gdrive_folder_parent: Array<Gdrive_Folder_Parent>;
  /** fetch aggregated fields from the table: "gdrive_folder_parent" */
  gdrive_folder_parent_aggregate: Gdrive_Folder_Parent_Aggregate;
  /** fetch data from the table: "gdrive_folder_parent" using primary key columns */
  gdrive_folder_parent_by_pk?: Maybe<Gdrive_Folder_Parent>;
  /** fetch data from the table: "gdrive_folder_upload" */
  gdrive_folder_upload: Array<Gdrive_Folder_Upload>;
  /** fetch aggregated fields from the table: "gdrive_folder_upload" */
  gdrive_folder_upload_aggregate: Gdrive_Folder_Upload_Aggregate;
  /** fetch data from the table: "gdrive_folder_upload" using primary key columns */
  gdrive_folder_upload_by_pk?: Maybe<Gdrive_Folder_Upload>;
  imageLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** fetch data from the table: "photo_label" */
  photo_label: Array<Photo_Label>;
  /** fetch aggregated fields from the table: "photo_label" */
  photo_label_aggregate: Photo_Label_Aggregate;
  /** fetch data from the table: "photo_label" using primary key columns */
  photo_label_by_pk?: Maybe<Photo_Label>;
};


export type Query_RootGdrive_FolderArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Bool_Exp>;
};


export type Query_RootGdrive_Folder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Bool_Exp>;
};


export type Query_RootGdrive_Folder_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootGdrive_Folder_ParentArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Parent_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Parent_Bool_Exp>;
};


export type Query_RootGdrive_Folder_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Parent_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Parent_Bool_Exp>;
};


export type Query_RootGdrive_Folder_Parent_By_PkArgs = {
  folder_id: Scalars['String'];
};


export type Query_RootGdrive_Folder_UploadArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Upload_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Upload_Bool_Exp>;
};


export type Query_RootGdrive_Folder_Upload_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Upload_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Upload_Bool_Exp>;
};


export type Query_RootGdrive_Folder_Upload_By_PkArgs = {
  folder_id: Scalars['String'];
  label_id: Scalars['Int'];
};


export type Query_RootImageLinksArgs = {
  folderId: Scalars['String'];
};


export type Query_RootPhoto_LabelArgs = {
  distinct_on?: InputMaybe<Array<Photo_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Label_Order_By>>;
  where?: InputMaybe<Photo_Label_Bool_Exp>;
};


export type Query_RootPhoto_Label_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Photo_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Label_Order_By>>;
  where?: InputMaybe<Photo_Label_Bool_Exp>;
};


export type Query_RootPhoto_Label_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "gdrive_folder" */
  gdrive_folder: Array<Gdrive_Folder>;
  /** fetch aggregated fields from the table: "gdrive_folder" */
  gdrive_folder_aggregate: Gdrive_Folder_Aggregate;
  /** fetch data from the table: "gdrive_folder" using primary key columns */
  gdrive_folder_by_pk?: Maybe<Gdrive_Folder>;
  /** fetch data from the table: "gdrive_folder_parent" */
  gdrive_folder_parent: Array<Gdrive_Folder_Parent>;
  /** fetch aggregated fields from the table: "gdrive_folder_parent" */
  gdrive_folder_parent_aggregate: Gdrive_Folder_Parent_Aggregate;
  /** fetch data from the table: "gdrive_folder_parent" using primary key columns */
  gdrive_folder_parent_by_pk?: Maybe<Gdrive_Folder_Parent>;
  /** fetch data from the table: "gdrive_folder_upload" */
  gdrive_folder_upload: Array<Gdrive_Folder_Upload>;
  /** fetch aggregated fields from the table: "gdrive_folder_upload" */
  gdrive_folder_upload_aggregate: Gdrive_Folder_Upload_Aggregate;
  /** fetch data from the table: "gdrive_folder_upload" using primary key columns */
  gdrive_folder_upload_by_pk?: Maybe<Gdrive_Folder_Upload>;
  /** fetch data from the table: "photo_label" */
  photo_label: Array<Photo_Label>;
  /** fetch aggregated fields from the table: "photo_label" */
  photo_label_aggregate: Photo_Label_Aggregate;
  /** fetch data from the table: "photo_label" using primary key columns */
  photo_label_by_pk?: Maybe<Photo_Label>;
};


export type Subscription_RootGdrive_FolderArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Bool_Exp>;
};


export type Subscription_RootGdrive_Folder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Bool_Exp>;
};


export type Subscription_RootGdrive_Folder_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootGdrive_Folder_ParentArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Parent_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Parent_Bool_Exp>;
};


export type Subscription_RootGdrive_Folder_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Parent_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Parent_Bool_Exp>;
};


export type Subscription_RootGdrive_Folder_Parent_By_PkArgs = {
  folder_id: Scalars['String'];
};


export type Subscription_RootGdrive_Folder_UploadArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Upload_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Upload_Bool_Exp>;
};


export type Subscription_RootGdrive_Folder_Upload_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdrive_Folder_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdrive_Folder_Upload_Order_By>>;
  where?: InputMaybe<Gdrive_Folder_Upload_Bool_Exp>;
};


export type Subscription_RootGdrive_Folder_Upload_By_PkArgs = {
  folder_id: Scalars['String'];
  label_id: Scalars['Int'];
};


export type Subscription_RootPhoto_LabelArgs = {
  distinct_on?: InputMaybe<Array<Photo_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Label_Order_By>>;
  where?: InputMaybe<Photo_Label_Bool_Exp>;
};


export type Subscription_RootPhoto_Label_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Photo_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Label_Order_By>>;
  where?: InputMaybe<Photo_Label_Bool_Exp>;
};


export type Subscription_RootPhoto_Label_By_PkArgs = {
  id: Scalars['Int'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};
